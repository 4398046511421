import React from 'react';

import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/nab/nab-show-hero-desktop.jpg';
import mobileBanner from '../../images/events/2025/nab/nab-show-hero-mobile.jpg';
import ogimage from '../../images/events/2025/nab/nab-show-og-image.jpg';

const NAB2025 = () => {
    const { isMobileBreakpoint } = useViewport();

    return (
        <Layout title='NAB Show Las Vegas 2025 - Witbe' isEvents>
            <SEO
                title='NAB Show Las Vegas 2025 - Witbe'
                ogDescription='Witbe is exhibiting at the NAB Show, one of the world’s largest and longest-running trade shows, dedicated to the broadcast, media, and entertainment industry.'
                description='Witbe is exhibiting at the NAB Show, one of the world’s largest and longest-running trade shows, dedicated to the broadcast, media, and entertainment industry.'
                image={ogimage}
            />

            {isMobileBreakpoint ? <MobileEventPage id={17} banner={mobileBanner} /> : <DesktopEventPage id={17} banner={banner} />}
        </Layout>
    );
};

export default NAB2025;
